// Here you can add other styles
.required
{
    color:red;
}
.validation-error
{
    color: red;
}
.card.bg-primary {
    border-color: #4cb050;
}
.bg-primary {
    background-color: #4cb050 !important;
}

.login a {
    color: #fff;
    text-decoration: underline;
}

.termsScroll {
    max-height: 420px;
    overflow-y: auto;
}