/* iCheck plugin Square skin, yellow
----------------------------------- */
.icheckbox_square-yellow,
.iradio_square-yellow {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 18px;
    height: 18px;
    background: url(yellow.png) no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_square-yellow {
    background-position: 0 0;
}
    .icheckbox_square-yellow.hover {
        background-position: -18px 0;
    }
    .icheckbox_square-yellow.checked {
        background-position: -36px 0;
    }
    .icheckbox_square-yellow.disabled {
        background-position: -54px 0;
        cursor: default;
    }
    .icheckbox_square-yellow.checked.disabled {
        background-position: -72px 0;
    }

.iradio_square-yellow {
    background-position: -90px 0;
}
    .iradio_square-yellow.hover {
        background-position: -108px 0;
    }
    .iradio_square-yellow.checked {
        background-position: -126px 0;
    }
    .iradio_square-yellow.disabled {
        background-position: -144px 0;
        cursor: default;
    }
    .iradio_square-yellow.checked.disabled {
        background-position: -162px 0;
    }

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    .icheckbox_square-yellow,
    .iradio_square-yellow {
        background-image: url(yellow@2x.png);
        -webkit-background-size: 180px 18px;
        background-size: 180px 18px;
    }
}