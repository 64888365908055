
.loading-indicator-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  background: rgba(0,0,0,.701961);
  -moz-transition: all 250ms linear;
  -o-transition: all 250ms linear;
  -webkit-transition: all 250ms linear;
  transition: all 250ms linear
}

.loading-indicator-wrapper.loader-visible {
  opacity: 1;
  z-index: 9999
}

.loading-indicator-wrapper.loader-hidden {
  opacity: 0;
  z-index: -1
}

.loading-indicator-wrapper img {
  vertical-align: middle;
  display: inline-block
}

.loading-indicator-wrapper .loader {
  margin: 0 auto;
  font-size: 10px;
  position: relative;
  display: inline-block;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255,255,255,.2);
  border-right: 1.1em solid rgba(255,255,255,.2);
  border-bottom: 1.1em solid rgba(255,255,255,.2);
  border-left: 1.1em solid #fff;
  -webkit-animation: load 1.1s infinite linear;
  animation: load 1.1s infinite linear
}

.loading-indicator-wrapper .loader,
.loading-indicator-wrapper .loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em
}

.loading-indicator-wrapper .loading-indicator-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle
}
@-webkit-keyframes 
load { 0% {
-webkit-transform:rotate(0deg);
transform:rotate(0deg)
}
100% {
-webkit-transform:rotate(360deg);
transform:rotate(360deg)
}
}
@keyframes 
load { 0% {
-webkit-transform:rotate(0deg);
transform:rotate(0deg)
}
100% {
-webkit-transform:rotate(360deg);
transform:rotate(360deg)
}
}
